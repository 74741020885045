import React, { useEffect, useState } from 'react';
import './Chatbot.css'; // CSS dosyasını içe aktarın

const Chatbot = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!document.querySelector('script[src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"]')) {
      const script = document.createElement('script');
      script.src = "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }, []);

  const wrapperStyle = {
    position: 'fixed',
    bottom: isMobile ? '200px' : '20px',
    right: '20px',
    zIndex: 1000,
  };

  return (
    <div className = "df-messenger" style={wrapperStyle}>
      <df-messenger
        intent="WELCOME"
        chat-title="Ubicraft"
        agent-id="28643e95-116e-42c5-8bdb-34b7d54c807a"
        language-code="tr"
      ></df-messenger>
    </div>
  );
};

export default Chatbot;
