import React, { useState, useEffect } from 'react';
import './HeroSection.css';
import Group from "./courseimages/Group.png";
import Laptop from "./courseimages/laptop.png"; // Import the new image
import { FaCircle, FaTimes, FaCode, FaPlus } from "react-icons/fa";  // Import icons

// TypingEffect Component
const TypingEffect = ({ texts, speed = 200, pause = 2000 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [forward, setForward] = useState(true);
  const [pauseState, setPauseState] = useState(false);

  useEffect(() => {
    if (pauseState) {
      const pauseTimeout = setTimeout(() => {
        setPauseState(false);
        setForward(!forward);
      }, pause);
      return () => clearTimeout(pauseTimeout);
    }

    if (forward) {
      if (subIndex < texts[index].length) {
        const timeout = setTimeout(() => {
          setDisplayedText((prev) => prev + texts[index][subIndex]);
          setSubIndex(subIndex + 1);
        }, speed);
        return () => clearTimeout(timeout);
      } else {
        setPauseState(true);
      }
    } else {
      if (subIndex > 0) {
        const timeout = setTimeout(() => {
          setDisplayedText((prev) => prev.slice(0, -1));
          setSubIndex(subIndex - 1);
        }, speed);
        return () => clearTimeout(timeout);
      } else {
        setPauseState(true);
        setIndex((prev) => (prev + 1) % texts.length);
      }
    }
  }, [subIndex, forward, pauseState, index, texts, speed, pause]);

  const getTextStyle = (text) => {
    if (texts[index] === 'Kalıcıdır') {
      return { color: '#F5841F' };
    } else if (texts[index] === 'Verimlidir') {
      return { color: '#78CAF3' };
    } else if (texts[index] === 'Hızlıdır') {
      return { color: '#58DD91' };
    } else if (texts[index] === 'Eğlencelidir') {
      return { color: '#FF7283' };
    }
    return {};
  };

  return <span className="animated-text-custom" style={getTextStyle(displayedText)}>{displayedText}</span>;
};

// HeroSection Component
const HeroSection = () => {
  return (
    <div className="hero-background-custom">
      <div className="css-selector-custom p-8 lg:p-20 flex items-center justify-between h-screen">
        <div className="text-left-custom">
          <div className="flex flex-col items-start justify-start mx-auto">
            <h1 className="font-bold text-[#301934] font-poppins flex items-center egitimlerimiz-custom">
              Eğitimlerimiz
            </h1>
            <div className="animated-text-container">
              <TypingEffect texts={['Kalıcıdır', 'Verimlidir', 'Hızlıdır', 'Eğlencelidir']} speed={50} pause={200} />
              <span className="animated-text-placeholder">&nbsp;</span>
            </div>
            <p className="text-2xl lg:text-4xl pt-4 font-semibold">
              <span className="text-[#301934]"><span className='text-[#fb8500]'>8-17 yaş</span> arası çocuklar için hazırlanmış</span> <span className='text-[#58DD91]'>çevrimiçi</span> <span className="text-[#301934]">kodlama kurslarıyla tanışın!</span>
            </p>
            <a
              href="https://docs.google.com/forms/d/1yP7OWJVAE7Bx8UNW96NdLrSsp9zAOkwdlbFBpbxpADU/edit"
              className="py-2 bg-orange-logo text-white font-semibold rounded-2xl shadow-md hover:bg-white hover:text-orange-logo transition-all font-poppins deneme-dersi-button-custom"
            >
              Ücretsiz Deneme Dersi
            </a>
          </div>
        </div>
        <div className="hidden lg:block w-1/2 image-container-custom">
          <img src={Group} alt="Group" className="w-full h-full object-cover" />
          <img src={Laptop} alt="Laptop" className="laptop-image-custom w-full h-full object-cover" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
