import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaGraduationCap, FaChalkboardTeacher, FaBook, FaTasks, FaChartLine, FaLaptopCode } from "react-icons/fa";
import "./EducationPlatform.css";
import studentImage from './ogrenci.png';
import teacherImage from './ogretmen.png';

const RefinedEducationPlatformComponent = () => {
  const [selected, setSelected] = useState("teacher");

  const platformData = {
    teacher: {
      image: teacherImage,
      features: [
        { icon: FaGraduationCap, title: "Öğrenci Yönetimi", description: "Öğrenci takibini olabildiğince kolaylaştırır" },
        { icon: FaBook, title: "Özelleştirilmiş Ödevler", description: "Her öğrenciye özel ödevler tasarlanır" },
        { icon: FaChartLine, title: "Notlandırma", description: "Öğrencinin ders içi aktivitesi ve ödevleri puanlanır" },
        { icon: FaTasks, title: "Yoklama ve Bildirimler", description: "Öğrenci eğer derse katılmazsa, öğretmen anında aksiyon alır" },
      ]
    },
    student: {
      image: studentImage,
      features: [
        { icon: FaBook, title: "Güçlü Müfredat", description: "Her kurs öğrencinin bir yazılımcı olmak için tüm gereksinimleri gözetilerek hazırlanmıştır" },
        { icon: FaTasks, title: "Ödevlendirme", description: "Öğrencinin ödevleri her hafta kontrol edilir ve geri bildirim verilir" },
        { icon: FaChartLine, title: "Gelişim Takibi", description: "Öğrencinin gelişiminden her zaman haberdar olun" },
        { icon: FaLaptopCode, title: "Online Python Programına Erişim", description: "Öğrencilere kodlarını yazabilmeleri için online bir Python programına erişim izni sağlanır" },
      ]
    }
  };

  return (
    <div className="refined-education-platform">
      <div className="content">
        <h1 className="edu-title">Eğitimde Yeni Nesil Çözümler</h1>
        <div className="platform-selector">
          <button
            className={`selector-btn ${selected === "teacher" ? "active" : ""}`}
            onClick={() => setSelected("teacher")}
          >
            <FaChalkboardTeacher /> Öğretmen Platformu
          </button>
          <button
            className={`selector-btn ${selected === "student" ? "active" : ""}`}
            onClick={() => setSelected("student")}
          >
            <FaGraduationCap /> Öğrenci Platformu
          </button>
        </div>
        <div className="platform-content">
          <motion.div 
            className="image-container"
            key={selected}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img src={platformData[selected].image} alt={`${selected} platform`} className="platform-image" />
          </motion.div>
          <div className="features-container">
            <AnimatePresence>
              {platformData[selected].features.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  className="feature-card"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <feature.icon className="feature-icon" />
                  <div className="feature-text">
                    <h3>{feature.title}</h3>
                    <p>{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefinedEducationPlatformComponent;