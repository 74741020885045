import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ubicraftLogo from "./courseimages/ubicraft.png";
import { FaHome, FaBook, FaCreditCard, FaUser, FaQuestionCircle} from "react-icons/fa";
import "./NavBar.css";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className="navbar">
      <div className="flex items-center w-full justify-between">
        <button onClick={() => { navigate("/"); }} className="ml-0 md:ml-6">
          <img src={ubicraftLogo} alt="ubicraft" className="h-12" />
        </button>
        <div className="desktop-menu">
          <a href="/" className={`text-gray-600 m-1 font-normal font-poppins hover:text-orange-logo hover:underline ${location.pathname === '/' ? 'active' : ''}`}>Ana Sayfa</a>
          <a href="/pricing" className={`text-gray-600 m-1 font-normal font-poppins hover:text-orange-logo hover:underline ${location.pathname === '/pricing' ? 'active' : ''}`}>Fiyatlandırma</a>
          <a href="/login" className={`text-gray-600 m-1 font-normal font-poppins hover:text-orange-logo hover:underline ${location.pathname === '/login' ? 'active' : ''}`}>Giriş Yap</a>
          <a href="/faqs" className={`text-gray-600 m-1 font-normal font-poppins hover:text-orange-logo hover:underline ${location.pathname === '/faqs' ? 'active' : ''}`}>S.S.S.</a>
          <button onClick={() => { navigate("/pricing"); }} className="rounded-xl bg-[#fb8500] text-[#FFFFFF] font-poppins font-semibold text-l w-52 h-10 justify-center items-center px-2 py-2" style={{ width: 250, marginTop: 0 }}>Planlarımızı Keşfedin</button>
        </div>
      </div>
      
      <div className="mobile-menu-bottom">
        <ul>
        <li onClick={() => navigate("/")} className={location.pathname === '/' ? 'active' : ''}><FaHome /> Home</li>
          <li onClick={() => navigate("/pricing")} className={location.pathname === '/pricing' ? 'active' : ''}><FaCreditCard /> Fiyatlandırma</li>

          <li onClick={() => navigate("/faqs")} className={location.pathname === '/faqs' ? 'active' : ''}><FaQuestionCircle /> S.S.S.</li>
          <li onClick={() => navigate("/login")} className={location.pathname === '/login' ? 'active' : ''}><FaUser /> Giriş Yap</li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
