import React, { useState } from "react";
import "./CoursesSection.css";
import { Link } from "react-router-dom";
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'; // Ok ikonları için
import P1Icon from "./courseimages/p1.png";
import P2Icon from "./courseimages/p2.png";
import P3Icon from "./courseimages/p3.png";

const CoursesSection = () => {
  const [flippedCards, setFlippedCards] = useState([]);

  const handleFlip = (index) => {
    if (flippedCards.includes(index)) {
      setFlippedCards(flippedCards.filter((i) => i !== index));
    } else {
      setFlippedCards([...flippedCards, index]);
    }
  };

  const courses = [
    {
      ageRange: "8-17 YAŞ",
      title: "Python Start",
      description:
        "Python'ın gücüyle tanışın, temellerini öğrenin. Uzman öğretmenler eşliğinde çizimler ve 2D oyunlar ile kodlama sanatını keşfedin!",
      duration: "16 Hafta",
      curriculum: [
        { week: "1-4 hafta", content: "Turtle ve Temel Syntax" },
        { week: "5-8 hafta", content: "Koşullu İfadeler ve Döngüler" },
        { week: "9-12 hafta", content: "Döngüler ve Algoritma" },
        { week: "13-16 hafta", content: "Listeler ve Sözlükler" },
        { week: "17-20 hafta", content: "Oyun Geliştirme M1" },
        { week: "21-24 hafta", content: "Oyun Geliştirme M2" },
        { week: "25-28 hafta", content: "Oyun Geliştirme M3" },
        { week: "29-32 hafta", content: "Mezuniyet Modülü" },
        // Diğer haftaları ekleyin
      ],
      level: "Başlangıç",
      tags: ["Algoritma", "Kodlama", "2D Oyunlar", "Çizimler"],
      icon: P1Icon,
    },
    {
      ageRange: "8-17 YAŞ",
      title: "Python +",
      description:
        "Python + ile yapay zeka uygulamaları geliştirin, veri analizine giriş yapın. Gerçek dünya projeleri ile pratiğini geliştirin ve tek başınıza bir proje geliştirmeye hazır hale gelin.",
      duration: "40 Hafta",
      curriculum: [
        { week: "1-4 hafta", content: "Doğal Dil İşleme Projeleri" },
        { week: "5-8 hafta", content: "Görüntü İşleme Projeleri" },
        { week: "9-12 hafta", content: "Ses İşleme Projeleri" },
        { week: "13-16 hafta", content: "Görüntü - Metin Birleştirme" },
        { week: "17-20 hafta", content: "Gelişmiş Entegrasyon" },
        { week: "21-24 hafta", content: "Mezuniyet Modülü" },
        // Diğer haftaları ekleyin
      ],
      level: "Başlangıç",
      tags: ["Algoritma", "Yapay Zeka", "Veri Analizi", "Projeler"],
      icon: P2Icon,
    },
    {
      ageRange: "11-17 YAŞ",
      title: "Python PRO",
      description:
        "Python deneyiminizi bir üst seviyeye taşıyın. Prompt mühendisiliği kavramlarıyla tanışın. Etkileyici projeler ve web siteleri geliştirirken çok daha hızlı olun. Bunları CV'nize ekleyerek erkenden kariyer fırsatlarını keşfedin. Geleceğin yıldızları için idealdir!",
      duration: "52 Hafta",
      curriculum: [
        { week: "1-4 hafta", content: "Etkili Prompt Yazma Teknikleri" },
        { week: "5-8 hafta", content: "Etik Yapay Zeka Kullanımı" },
        { week: "9-12 hafta", content: "Mezuniyet Modülü" },

        // Diğer haftaları ekleyin
      ],
      level: "İleri düzey",
      tags: ["Prompt Eng.", "Web Sitesi", "Yapay Zeka", "İleri Düzey"],
      icon: P3Icon,
    },
  ];

  return (
    <div className="mt-8">
      <div className="flex justify-center items-center">
        <p className="courses-title">
          <span className="courses-title-highlight">Kurslarımız </span>
        </p>
      </div>

      <div className="flex justify-center items-center mt-4">
        <p className="text-center text-[#301934] font-poppins font-normal text-2xl">
          Yeni nesil kurslarımızı ücretsiz deneme dersi ile keşfedin!
        </p>
      </div>
      <div className="CoursesSectionParentCard flex gap-10 px-10 py-8 mt-8 mx-8 justify-start items-center overflow-x-auto">
        {courses.map((course, index) => (
          <div
            key={index}
            className={`CoursesSectionCard w-80 h-[650px] px-6 py-4 bg-orange-1 rounded-[20px] flex flex-col justify-between relative ${
              flippedCards.includes(index) ? "flipped" : ""
            }`}
          >
            <div className="card-front flex flex-col gap-6">
              <img src={course.icon} alt={`Icon ${index + 1}`} className="icon" />
              <div>
                <p className="font-poppins font-bold text-2xl text-[#301934]">
                  {course.title}
                </p>
                <p className="w-[22px] h-[2px] bg-orange-logo mt-1"></p>
              </div>
              <div className="flex flex-col justify-center items-center mt-8">
                <p className="font-poppins font-semibold text-3xl text-[#301934]">
                  {course.ageRange}
                </p>
                <p className="font-poppins font-normal text-xl text-[#747474]">
                  {course.duration}
                </p>
              </div>
              <div className="flex flex-col gap-4 mt-5">
                <div>
                  <p className="font-poppins font-semibold text-sm text-[#301934]">
                    Sizi neler bekliyor?
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="font-poppins font-normal text-[#301934] text-sm">
                    {course.description}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 mt-5">
                {course.tags.map((tag, i) => (
                  <span
                    key={i}
                    className="bg-white text-[#301934] font-poppins font-medium rounded-full px-3 py-1 text-xs"
                  >
                    {tag}
                  </span>
                ))}
              </div>
              <div className="flex justify-center items-center mt-6">
                <button
                  onClick={() => handleFlip(index)}
                  className="view-curriculum-btn"
                >
                  Kurs müfredatını göster <FaArrowRight />
                </button>
              </div>
              <div className="flex rounded-lg mt-4 justify-center items-center">
                <Link
                  to="/#registration"
                  className="enroll-now-btn"
                >
                  Şimdi Kaydolun
                </Link>
              </div>
            </div>
            <div className="card-back flex flex-col justify-start items-start p-4">
              <p className="font-poppins font-bold text-2xl text-[#301934] mb-4">
                Müfredat
              </p>
              <div>
                {course.curriculum.map((item, i) => (
                  <div key={i} className="curriculum-item">
                    <span className="curriculum-week">{item.week}</span> {item.content}
                  </div>
                ))}
              </div>
              <div className="flex justify-center items-center mt-6">
                <button
                  onClick={() => handleFlip(index)}
                  className="hide-curriculum-btn"
                >
                  Kurs müfredatını gizle <FaArrowLeft />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoursesSection;
