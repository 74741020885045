import React, { useState } from 'react';
import {
  FaLaptopCode,
  FaRobot,
  FaUserGraduate,
  FaRocket,
  FaBrain,
  FaLightbulb,
  FaChartLine,
  FaProjectDiagram,
  FaUsers,
} from 'react-icons/fa';
import './Company.css';
import TermsAndConditionsPopup from './TermsAndConditionsPopup';
import PrivacyPolicyPopup from './PrivacyPolicyPopup';

const CourseCard = ({ title, description, features, certificate, icon: Icon }) => (
  <div className="course-card">
    <div className="course-icon">
      <Icon />
    </div>
    <h3 className="course-title">{title}</h3>
    <p className="course-description">{description}</p>
    <ul className="course-features">
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
    <div className="course-certificate">
      <FaUserGraduate />
      <p>{certificate}</p>
    </div>
  </div>
);

const InteractiveKidsCodingCourses = () => {
  const [selectedCourse, setSelectedCourse] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const coursesData = [
    {
      title: 'Python Start',
      description:
        "Çocuğunuzun kodlama dünyasına ilk adımı! Eğlenceli projelerle Python'un temellerini öğrenin.",
      features: [
        'Temel programlama kavramları',
        'Eğlenceli oyun projeleri',
        'Görsel programlama araçları',
        '8-17 yaş grubu için uygun',
      ],
      certificate: 'Python Start Sertifikası',
      icon: FaLaptopCode,
    },
    {
      title: 'Python Plus',
      description:
        'Kodlama becerilerini bir üst seviyeye taşı! Daha karmaşık projeler ve algoritma çözümleri.',
      features: [
        'İleri düzey Python kavramları',
        'Yapay zeka ve makine öğrenimi temelleri',
        'Web geliştirme temelleri',
        '12-17 yaş grubu için uygun',
      ],
      certificate: 'Python Plus Sertifikası',
      icon: FaRobot,
    },
    {
      title: 'Python Pro',
      description:
        'Gerçek dünya problemlerini çöz! Yapay zeka ve veri bilimi projelerine giriş yap.',
      features: [
        'Yapay zeka ve makine öğrenimi temelleri',
        'Prompt Engineering teknikleri',
        'Büyük projeler ve hackathonlar',
        '12-17 yaş grubu için uygun',
      ],
      certificate: 'Python Pro Sertifikası',
      icon: FaUserGraduate,
    },
  ];

  const skills = [
    { name: 'Algoritmik düşünme', icon: FaBrain },
    { name: 'Yaratıcı problem çözme', icon: FaLightbulb },
    { name: 'Mantıksal akıl yürütme', icon: FaChartLine },
    { name: 'Proje yönetimi', icon: FaProjectDiagram },
    { name: 'Takım çalışması', icon: FaUsers },
  ];

  const handleButtonClick = (e) => {
    if (!isChecked) {
      e.preventDefault();
      setErrorMessage(
        'Lütfen Şartlar ve Koşullar, Gizlilik Sözleşmesi ve KVKK sözleşmelerini kabul ediniz.'
      );
    } else {
      setErrorMessage('');
      window.location.href =
        'https://docs.google.com/forms/d/1yP7OWJVAE7Bx8UNW96NdLrSsp9zAOkwdlbFBpbxpADU/';
    }
  };

  return (
    <div className="interactive-coding-container">
      <h2 className="coding-title">Geleceğin kapılarını aralamaya ne dersiniz?</h2>
      <div className="coding-content">
        <div className="course-section">
          <div className="course-tabs">
            {coursesData.map((course, index) => (
              <button
                key={index}
                className={`course-tab ${selectedCourse === index ? 'active' : ''}`}
                onClick={() => setSelectedCourse(index)}
              >
                {course.title}
              </button>
            ))}
          </div>
          <CourseCard {...coursesData[selectedCourse]} />
        </div>
        <div className="promo-section">
          <h3 className="promo-title">Kodlama ile Geleceğe Yolculuk!</h3>
          <p className="promo-description">
            Çocuğunuzun hayal gücünü ve problem çözme becerilerini geliştirin. Python ile kodlama
            öğrenerek teknoloji dünyasında öne çıkmasını sağlayın!
          </p>
          <h4 className="skills-title">Kazanılacak Beceriler:</h4>
          <ul className="skills-list">
            {skills.map((skill, index) => (
              <li key={index}>
                <skill.icon className="skill-icon" />
                {skill.name}
              </li>
            ))}
          </ul>
          <div className="terms-conditions">
            <input
              type="checkbox"
              id="terms-checkbox"
              checked={isChecked}
              onChange={() => {
                setIsChecked(!isChecked);
                setErrorMessage('');
              }}
            />
            <label htmlFor="terms-checkbox">
              <span className="link2" onClick={() => setShowTerms(true)}>
                Şartlar ve Koşulları
              </span>
              ,
              <span className="link2" onClick={() => setShowPrivacy(true)}>
                Gizlilik Sözleşmesi ve KVKK
              </span>
              'yı kabul ediyorum.
            </label>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button
            className={`free-trial-button ${isChecked ? '' : 'disabled'}`}
            onClick={handleButtonClick}
          >
            <FaRocket className="button-icon" />
            Ücretsiz Deneme Dersi Al
          </button>
        </div>
      </div>

      <TermsAndConditionsPopup isOpen={showTerms} onClose={() => setShowTerms(false)} />

      <PrivacyPolicyPopup isOpen={showPrivacy} onClose={() => setShowPrivacy(false)} />
    </div>
  );
};

export default InteractiveKidsCodingCourses;
