import React, { useState, useEffect } from 'react';
import './CurriculumManagement.css';

const CurriculumManagement = ({ selectedCourseId }) => {
  const [activeTab, setActiveTab] = useState('lessons'); // Başlangıç tabı 'lessons'

  // Ders Yönetimi için state'ler
  const [lessons, setLessons] = useState([]);
  const [newLesson, setNewLesson] = useState({
    week_number: '',
    lesson_name: '',
    details: '',
    is_project_week: false,
  });
  const [editingLesson, setEditingLesson] = useState(null);

  // Aktivite Yönetimi için state'ler
  const [selectedActivityCourseName, setSelectedActivityCourseName] = useState('');
  const [activities, setActivities] = useState([]);
  const [newActivity, setNewActivity] = useState({
    activity_id: '',
    week_id: '',
    activity_name: '',
    replit_url: '',
  });
  const [editingActivity, setEditingActivity] = useState(null);

  // Popup için state
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  // Kurs isimleri listesi
  const courseNames = [
    'Python Start',
    'Python +',
    'Python Pro',
    'Python Start Deneme',
    'Python + Deneme',
  ];

  // Dersleri fetch etme
  useEffect(() => {
    if (selectedCourseId) {
      fetch(`https://backend.ubicraft.org/api/courses/${selectedCourseId}/lessons`)
        .then(response => response.json())
        .then(data => setLessons(data))
        .catch(error => console.error('Dersleri getirirken hata oluştu:', error));
    }
  }, [selectedCourseId]);

  // Aktiviteleri fetch etme
  useEffect(() => {
    if (selectedActivityCourseName) {
      fetch(`https://backend.ubicraft.org/api/courses/${encodeURIComponent(selectedActivityCourseName)}/activities`)
        .then(response => response.json())
        .then(data => setActivities(data))
        .catch(error => console.error('Aktiviteleri getirirken hata oluştu:', error));
    } else {
      setActivities([]); // Kurs seçilmediyse aktiviteleri temizle
    }
  }, [selectedActivityCourseName]);

  // Ders Yönetimi fonksiyonları
  const handleLessonInputChange = (e, isEditing = false) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === 'checkbox' ? checked : value;

    if (isEditing) {
      setEditingLesson({
        ...editingLesson,
        [name]: updatedValue,
      });
    } else {
      setNewLesson({
        ...newLesson,
        [name]: updatedValue,
      });
    }
  };

  const handleSaveNewLesson = () => {
    fetch(`https://backend.ubicraft.org/api/courses/${selectedCourseId}/lessons`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newLesson),
    })
      .then(response => response.json())
      .then(data => {
        setLessons([...lessons, data]);
        setNewLesson({
          week_number: '',
          lesson_name: '',
          details: '',
          is_project_week: false,
        });
        showPopupMessage('Ders başarıyla eklendi.');
      })
      .catch(error => console.error('Yeni ders eklerken hata oluştu:', error));
  };

  const handleSaveEditedLesson = (lessonId) => {
    fetch(`https://backend.ubicraft.org/api/lessons/${lessonId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editingLesson),
    })
      .then(response => response.json())
      .then(updatedLesson => {
        setLessons(lessons.map(lesson => (lesson.id === lessonId ? updatedLesson : lesson)));
        setEditingLesson(null);
        showPopupMessage('Ders başarıyla güncellendi.');
      })
      .catch(error => console.error('Dersi güncellerken hata oluştu:', error));
  };

  const handleDeleteLesson = (lessonId) => {
    fetch(`https://backend.ubicraft.org/api/lessons/${lessonId}`, { method: 'DELETE' })
      .then(response => {
        if (response.ok) {
          setLessons(lessons.filter(lesson => lesson.id !== lessonId));
          showPopupMessage('Ders başarıyla silindi.');
        }
      })
      .catch(error => console.error('Dersi silerken hata oluştu:', error));
  };

  // Aktivite Yönetimi fonksiyonları
  const handleActivityInputChange = (e, isEditing = false) => {
    const { name, value } = e.target;
    if (isEditing) {
      setEditingActivity({
        ...editingActivity,
        [name]: value,
      });
    } else {
      setNewActivity({
        ...newActivity,
        [name]: value,
      });
    }
  };

  const handleSaveNewActivity = () => {
    const newActivityData = {
      ...newActivity,
      course_name: selectedActivityCourseName,
    };
    fetch(`https://backend.ubicraft.org/api/courses/${encodeURIComponent(selectedActivityCourseName)}/activities`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newActivityData),
    })
      .then(response => response.json())
      .then(data => {
        setActivities([...activities, data]);
        setNewActivity({
          activity_id: '',
          week_id: '',
          activity_name: '',
          replit_url: '',
        });
        showPopupMessage('Aktivite başarıyla eklendi.');
      })
      .catch(error => console.error('Yeni aktivite eklerken hata oluştu:', error));
  };

  const handleSaveEditedActivity = (activityId) => {
    fetch(`https://backend.ubicraft.org/api/activities/${activityId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editingActivity),
    })
      .then(response => response.json())
      .then(updatedActivity => {
        setActivities(activities.map(activity => (activity.id === activityId ? updatedActivity : activity)));
        setEditingActivity(null);
        showPopupMessage('Aktivite başarıyla güncellendi.');
      })
      .catch(error => console.error('Aktiviteyi güncellerken hata oluştu:', error));
  };

  const handleDeleteActivity = (activityId) => {
    fetch(`https://backend.ubicraft.org/api/activities/${activityId}`, { method: 'DELETE' })
      .then(response => {
        if (response.ok) {
          setActivities(activities.filter(activity => activity.id !== activityId));
          showPopupMessage('Aktivite başarıyla silindi.');
        }
      })
      .catch(error => console.error('Aktiviteyi silerken hata oluştu:', error));
  };

  // Popup mesajını gösterme fonksiyonu
  const showPopupMessage = (message) => {
    setPopupMessage(message);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
      setPopupMessage('');
    }, 3000); // Popup 3 saniye sonra kaybolur
  };

  return (
    <div className="curriculum-management">
      <h2>Müfredat Yönetimi</h2>
      <div className="tabs">
        <button className={activeTab === 'lessons' ? 'active' : ''} onClick={() => setActiveTab('lessons')}>
          Ders Yönetimi
        </button>
        <button className={activeTab === 'activities' ? 'active' : ''} onClick={() => setActiveTab('activities')}>
          Aktivite Yönetimi
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'lessons' && (
          <div className="tab-panel">
            {/* Ders Yönetimi İçeriği */}
            <div className="new-lesson-form">
              <h3>Yeni Ders Ekle</h3>
              <input
                type="number"
                name="week_number"
                placeholder="Hafta Numarası"
                value={newLesson.week_number}
                onChange={handleLessonInputChange}
              />
              <input
                type="text"
                name="lesson_name"
                placeholder="Ders Adı"
                value={newLesson.lesson_name}
                onChange={handleLessonInputChange}
              />
              <textarea
                name="details"
                placeholder="Ders Detayları"
                value={newLesson.details}
                onChange={handleLessonInputChange}
              />
              <label>
                <input
                  type="checkbox"
                  name="is_project_week"
                  checked={newLesson.is_project_week}
                  onChange={handleLessonInputChange}
                />
                Proje Haftası
              </label>
              <button onClick={handleSaveNewLesson}>Ders Ekle</button>
            </div>

            <div className="lessons-list">
              <h3>Dersler</h3>
              {lessons.map(lesson => (
                <div key={lesson.id} className="lesson-item">
                  {editingLesson?.id === lesson.id ? (
                    <>
                      <input
                        type="number"
                        name="week_number"
                        value={editingLesson.week_number}
                        onChange={(e) => handleLessonInputChange(e, true)}
                      />
                      <input
                        type="text"
                        name="lesson_name"
                        value={editingLesson.lesson_name}
                        onChange={(e) => handleLessonInputChange(e, true)}
                      />
                      <textarea
                        name="details"
                        value={editingLesson.details}
                        onChange={(e) => handleLessonInputChange(e, true)}
                      />
                      <label>
                        <input
                          type="checkbox"
                          name="is_project_week"
                          checked={editingLesson.is_project_week}
                          onChange={(e) => handleLessonInputChange(e, true)}
                        />
                        Proje Haftası
                      </label>
                      <button onClick={() => handleSaveEditedLesson(lesson.id)}>Kaydet</button>
                      <button onClick={() => setEditingLesson(null)}>İptal</button>
                    </>
                  ) : (
                    <>
                      <div className="lesson-details">
                        <p><strong>Hafta {lesson.week_number}:</strong> {lesson.lesson_name}</p>
                        <p>{lesson.details}</p>
                        <p><strong>Proje Haftası:</strong> {lesson.is_project_week ? 'Evet' : 'Hayır'}</p>
                      </div>
                      <div className="lesson-actions">
                        <button onClick={() => setEditingLesson(lesson)}>Düzenle</button>
                        <button onClick={() => handleDeleteLesson(lesson.id)}>Sil</button>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === 'activities' && (
          <div className="tab-panel">
            {/* Aktivite Yönetimi İçeriği */}
            <div className="activity-course-selection">
              <label>
                Kurs Seçiniz:
                <select
                  value={selectedActivityCourseName}
                  onChange={(e) => setSelectedActivityCourseName(e.target.value)}
                >
                  <option value="">-- Kurs Seçiniz --</option>
                  {courseNames.map((courseName) => (
                    <option key={courseName} value={courseName}>{courseName}</option>
                  ))}
                </select>
              </label>
            </div>

            {selectedActivityCourseName && (
              <>
                <div className="new-activity-form">
                  <h3>Yeni Aktivite Ekle</h3>
                  <input
                    type="text"
                    name="activity_id"
                    placeholder="Aktivite ID"
                    value={newActivity.activity_id}
                    onChange={handleActivityInputChange}
                  />
                  <input
                    type="number"
                    name="week_id"
                    placeholder="Hafta Numarası"
                    value={newActivity.week_id}
                    onChange={handleActivityInputChange}
                  />
                  <input
                    type="text"
                    name="activity_name"
                    placeholder="Aktivite Adı"
                    value={newActivity.activity_name}
                    onChange={handleActivityInputChange}
                  />
                  <input
                    type="text"
                    name="replit_url"
                    placeholder="Replit URL"
                    value={newActivity.replit_url}
                    onChange={handleActivityInputChange}
                  />
                  <button onClick={handleSaveNewActivity}>Aktivite Ekle</button>
                </div>

                <div className="activities-list">
                  <h3>Aktiviteler - {selectedActivityCourseName}</h3>
                  <table className="activities-table">
                    <thead>
                      <tr>
                        <th>Kurs</th>
                        <th>Aktivite ID</th>
                        <th>Hafta No</th>
                        <th>Aktivite Adı</th>
                        <th>Replit URL</th>
                        <th>İşlemler</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activities.map(activity => (
                        <tr key={activity.id}>
                          {editingActivity?.id === activity.id ? (
                            <>
                              <td>
                                <select
                                  name="course_name"
                                  value={editingActivity.course_name}
                                  onChange={(e) => handleActivityInputChange(e, true)}
                                >
                                  {courseNames.map((courseName) => (
                                    <option key={courseName} value={courseName}>{courseName}</option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="activity_id"
                                  value={editingActivity.activity_id}
                                  onChange={(e) => handleActivityInputChange(e, true)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="week_id"
                                  value={editingActivity.week_id}
                                  onChange={(e) => handleActivityInputChange(e, true)}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="activity_name"
                                  value={editingActivity.activity_name}
                                  onChange={(e) => handleActivityInputChange(e, true)}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="replit_url"
                                  value={editingActivity.replit_url}
                                  onChange={(e) => handleActivityInputChange(e, true)}
                                />
                              </td>
                              <td>
                                <button onClick={() => handleSaveEditedActivity(activity.id)}>Kaydet</button>
                                <button onClick={() => setEditingActivity(null)}>İptal</button>
                              </td>
                            </>
                          ) : (
                            <>
                              <td>{activity.course_name}</td>
                              <td>{activity.activity_id}</td>
                              <td>{activity.week_id}</td>
                              <td>{activity.activity_name}</td>
                              <td>
                                <a href={activity.replit_url} target="_blank" rel="noopener noreferrer">
                                  Link
                                </a>
                              </td>
                              <td>
                                <button onClick={() => setEditingActivity(activity)}>Düzenle</button>
                                <button onClick={() => handleDeleteActivity(activity.id)}>Sil</button>
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* Popup Bileşeni */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>{popupMessage}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurriculumManagement;
