import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ChevronRight, Book, CheckCircle, Rocket, Code, PlayCircle } from 'lucide-react';
import Python from "../../Icons/Python.png";
import Replit from "../../Icons/trinket-logo-circles.png";
import "./Home.css";

const Home = () => {
  const { userId } = useParams();
  const [completedWeeks, setCompletedWeeks] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [courseDetails, setCourseDetails] = useState({});
  const [totalWeeks, setTotalWeeks] = useState(0);
  const [meetingUrl, setMeetingUrl] = useState(""); // To store the meeting link
  const navigate = useNavigate();
  
  const handleNewProjectClick = () => {
    // Add your new project logic here
    console.log("New project button clicked");
    // You can navigate to a new page or perform any action needed
    // Example: navigate to a new project creation page
    // navigate('/new-project');
  };


  const fetchCompletedWeeks = useCallback(async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`https://backend.ubicraft.org/api/progress/${userId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    setCompletedWeeks(data.completedWeeks);
    if (totalWeeks > 0) {
      setProgressPercentage((data.completedWeeks.length / totalWeeks) * 100);
    }
  }, [userId, totalWeeks]);

  const fetchCourseDetails = useCallback(async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`https://backend.ubicraft.org/api/student/${userId}/course-details`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    setCourseDetails(data);
    setTotalWeeks(data.total_weeks);
  }, [userId]);

  const fetchMeetingLink = useCallback(async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`https://backend.ubicraft.org/api/student/${userId}/meeting-link`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    if (data.meeting_url) {
      setMeetingUrl(data.meeting_url); // Set the meeting URL
    }
  }, [userId]);

  useEffect(() => {
    fetchCourseDetails();
  }, [fetchCourseDetails]);

  useEffect(() => {
    if (totalWeeks > 0) {
      fetchCompletedWeeks();
    }
  }, [fetchCompletedWeeks, totalWeeks]);

  const handleProgressClick = () => navigate(`/student/${userId}/notification`);

  const handleStartLessonClick = () => {
    fetchMeetingLink().then(() => {
      if (meetingUrl) {
        window.open(meetingUrl, '_blank'); // Opens the link in a new tab
      }
    });
  };
  

  return (
    <div className="home-container">
      <div className="home-track-card">
        <div className="home-track-header">
          <span className="home-track-title">SERTİFİKA İÇİN HAZIRLANIN</span>
          <h1>{courseDetails.course_name}</h1>
          <p className="home-track-description">{courseDetails.course_name} kursuna kayıt oldunuz. Bu sekmede ilerlemenizi takip edebilir, canlı derse bağlanabilirsiniz.</p>
        </div>
        <div className="home-track-info">
          <div className="home-progress-container">
            <div className="circular-progress">
              <CircularProgressbar
                value={progressPercentage}
                text={`${Math.round(progressPercentage)}%`}
                styles={buildStyles({
                  textSize: '16px',
                  pathColor: '#fb8500',
                  textColor: '#ffffff',
                  trailColor: '#4A4A4A',
                })}
              />
            </div>
            <div className="next-lesson-box">
              <div className="next-lesson-content">
              <div className="lesson-infom">
                <h3>Sıradaki Ders</h3>
                <p>{courseDetails.next_title}</p>
              </div>

                <button className="start-lesson-button" onClick={handleStartLessonClick}>
                  <PlayCircle size={20} />
                  Derse Başla
                </button>
              </div>
            </div>
            <div className="home-progress-details">
              <span>{totalWeeks - completedWeeks.length} hafta kaldı</span>
              <button className="home-progress-button" onClick={handleProgressClick}>
                Kursa Devam Et <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </div>
        <div className="home-course-footer">
          {[
            { icon: <Book size={24} />, title: "GÖZDEN GEÇİRİN", content: `${completedWeeks.length} Ders` },
            { icon: <CheckCircle size={24} />, title: "SIRADAKİ DERS", content: courseDetails.next_title },
            { icon: <Code size={24} />, title: "ÖDEVLERİ TAMAMLAYIN", content: courseDetails.practice_title },
            { icon: <Rocket size={24} />, title: "SIRADAKİ PROJE", content: courseDetails.apply_title }
          ].map((section, index) => (
            <div key={index} className="home-course-section">
              <div className="home-section-header">
                {section.icon}
                <span className="home-section-title">{section.title}</span>
              </div>
              <div className="home-section-body">
                <span>{section.content}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="replit-container">
        {[
          {
            title: "Bir gün değil, her gün öğrenin!",
            description: "Her hafta yeni bir Replit projesi ile öğreniminize ara vermeden devam edin!",
            buttonText: "Etkinliğe Git",
            onClick: () => { }
          },
          {
            title: "Kendi projenizi oluşturun!",
            description: "Platformdaki etkinliklerle sınırlı kalmayın ve potansiyelinizi yeni bir proje ile açığa çıkartın.",
            buttonText: "Yeni Proje Oluştur",
            onClick: handleNewProjectClick
          }
        ].map((card, index) => (
          <div key={index} className="replit-card">
            <div className="replit-header">
              <img src={Replit} alt="Replit" className="replit-icon" />
              <div className="replit-content">
                <h2 className="replit-title">{card.title}</h2>
                <p className="replit-description">{card.description}</p>
              </div>
            </div>
            <button className="replit-button" onClick={card.onClick}>{card.buttonText}</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
